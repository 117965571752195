import { Controller } from "@hotwired/stimulus";
import { hideV2, showV2, isHiddenV2 } from "../utils/display";

export default class extends Controller {
  static targets = ['skeleton', 'content', 'grid', 'table']

  displaySkeleton({target}) {
    const { skeleton } = target.dataset
    if (skeleton && this.hasContentTarget) {
      hideV2(this.contentTarget)
    }

    if (skeleton && this.hasSkeletonTarget) {
      if (this.hasGridTarget && isHiddenV2(this.gridTarget)) {
        hideV2(...this.tableTargets)
        showV2(...this.gridTargets)
      }
      showV2(this.skeletonTargets.filter(({dataset}) =>
        (dataset.skeleton || dataset.frame) === skeleton
      )[0])
    }
  }

  hideSkeleton({target}) {
    if (this.hasSkeletonTarget && target.id) {
      hideV2(...this.skeletonTargets.filter(element => element.dataset.frame == target.id))
    } else if (this.hasSkeletonTarget) {
      hideV2(...this.skeletonTargets)
    }

    if (this.hasContentTarget) {
      showV2(this.contentTarget)
    }
  }
}